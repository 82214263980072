import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  //  Chip,
  Drawer,
  //  Stack,
  useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
// import { BrowserView } from 'react-device-detect';

// project imports
// import MenuList from './MenuList';

// import MenuCard from './MenuCard';
import PageList from './PageList';
import { drawerWidth, mobileWidth } from 'store/constant';

const Sidebar = ({ drawerOpen = false, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const drawer = (
    <>
      {/* <BrowserView> */}
      <PerfectScrollbar
        component="div"
        style={{
          height: !matchUpMd ? 'calc(100vh)' : 'calc(100vh)',
          padding: '0 20px',
          backgroundColor: '#EAF1FE'
        }}
      >
        <PageList matchUpMd={matchUpMd} />
      </PerfectScrollbar>
      {/* </BrowserView> */}
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: matchUpMd ? drawerWidth : mobileWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '80px'
            }
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
