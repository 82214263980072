import React from 'react';
import { NavLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import './style.css';
import Logo from '../../../../assets/images/Logo';
import { Typography } from '@mui/material';
import { allRoutes } from 'routes/routers';

const browserStyle = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '0.5rem',

  // gap: '28svh',

  navlink: {
    // width: 'fit-content',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    gap: '4px',
    fontSize: ' 0.8rem',
    margin: '1rem -0.4rem',
    padding: '0.6rem 0.5rem',
    color: ' #4E5663'
  },
  askAsato: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    gap: '4px',
    fontSize: '12px',
    margin: '0rem -0.4rem',
    padding: '0.6rem 0',
    color: ' #4E5663',
    position: 'relative'
  }
};

const mobileStyle = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '0.5rem',
  // gap: '50svh',
  navlink: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: ' 0.8rem',
    margin: '0.2rem -0.4rem',
    padding: '0.6rem 0.5rem',
    color: ' #4E5663',
    marginLeft: '2rem',
    gap: '8px'
  },
  askAsato: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: ' 0.8rem',
    margin: '0.2rem -0.4rem',
    padding: '0.6rem 0.5rem',
    color: ' #4E5663',
    marginLeft: '2rem',
    gap: '8px',
    position: 'relative'
  }
};
const Index = ({ matchUpMd }) => {
  const betaStyle = {
    position: 'absolute',
    top: '-5px',
    right: '-10px',
    fontSize: '9px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    backgroundColor: '#2A76F4',
    borderRadius: '10px',
    padding: '2px 6px',
    textTransform: 'uppercase'
  };

  const topSideBar = [];
  const middleSideBar = [];
  const bottomSideBar = [];

  allRoutes?.forEach((route) => {
    if (route.sidebar) {
      if (route.position === 'top') {
        topSideBar.push(route);
      } else if (route.position === 'middle') {
        middleSideBar.push(route);
      } else if (route.position === 'bottom') {
        bottomSideBar.push(route);
      }
    }
  });

  return (
    <>
      {!matchUpMd ? (
        <span
          style={{
            display: 'flex',
            // justifyContent:'center',
            margin: '1.5rem 0 1rem 2rem '
          }}
        >
          <Logo fill="#021A42" />
        </span>
      ) : null}

      <aside style={(matchUpMd ? browserStyle : mobileStyle, { height: 'calc(100vh - 100px)', justifyContent: 'space-between' })}>
        <div>
          {topSideBar.map((item) => (
            <NavLink
              data-test-id={`sidebar-tab-${item.name}`}
              to={item?.onClickPath ? item?.onClickPath : item.path}
              key={item?.onClickPath ? item?.onClickPath : item.path}
              style={matchUpMd ? browserStyle.navlink : mobileStyle.navlink}
            >
              {item.svg ? item.svg : <i className={item.icon}></i>}
              <Typography size="small">{item.name}</Typography>
            </NavLink>
          ))}
          <Divider sx={{ borderBottomWidth: 3, mx: 1, my: 1, borderColor: matchUpMd ? '#B3B6BC' : 'white' }} />
          {middleSideBar.map((item) => (
            <NavLink
              data-test-id={`sidebar-tab-${item.name}`}
              to={item?.onClickPath ? item?.onClickPath : item.path}
              key={item?.onClickPath ? item?.onClickPath : item.path}
              style={matchUpMd ? browserStyle.navlink : mobileStyle.navlink}
            >
              {item.svg ? item.svg : <i className={item.icon}></i>}
              <Typography size="small">{item.name}</Typography>
              {item?.name === 'Tasks' && <Typography sx={betaStyle}>BETA</Typography>}
            </NavLink>
          ))}
        </div>

        <div>
          {bottomSideBar.map((item, index) =>
            item?.name === 'Help' ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={item?.path}
                key={index}
                style={matchUpMd ? browserStyle.navlink : mobileStyle.navlink}
              >
                {item.svg ? item.svg : <i className={item.icon}></i>}
                <Typography>{item.name}</Typography>
              </a>
            ) : (
              <NavLink
                data-test-id={`sidebar-tab-${item.name}`}
                to={item?.onClickPath ? item?.onClickPath : item.path}
                key={item?.onClickPath ? item?.onClickPath : item.path}
                style={matchUpMd ? browserStyle.askAsato : mobileStyle.askAsato}
              >
                {item.svg ? item.svg : <i className={item.icon}></i>}
                <span>{item.name}</span>
                {item?.name === 'Ask Asato' && <Typography sx={betaStyle}>BETA</Typography>}
              </NavLink>
            )
          )}
        </div>
      </aside>
    </>
  );
};

export default Index;
